const accueilcarousel = new Swiper("#carousel-accueil", {
  slidesPerView: 1,
  speed: 500,
  effect: "fade",
  autoplay: {
    delay: 5000,
  },
  loop: true,
  navigation: {
    prevEl: ".accueil__swiper__control--prev",
    nextEl: ".accueil__swiper__control--next",
  },
});

accueilcarousel.on("slideChange", () => {
  jQuery("#carousel-accueil-currentslide").text(accueilcarousel.realIndex + 1);
  jQuery("#carousel-accueil-currentslide")
    .addClass("animation")
    .delay(500)
    .queue(() => {
      jQuery(this).removeClass("animation").dequeue();
    });
});

new CircleType(document.getElementById("simple_arc"));
